/* LANDING PAGE */
.landing-hero {
    background: url("../../public/images/dataBg.png") no-repeat center bottom;
    background-size: cover;
    box-shadow: 0 0 2.4rem 0 rgba(0, 0, 0, .5);
    border-radius: 3.2rem;
    margin-left: 1.6rem;
    margin-right: 1.6rem;
    position: relative;
    min-height: 50rem;
    @media (min-width: 640px) {
      min-height: 60rem;
    }
  }
  .landing-section__content,
  .landing-section-viewer__wrapper,
  .landing-section-contact__info {
    box-shadow: inset 0 0 20rem 0 rgba(255, 255, 255, .1), 0 0 2.4rem 0 rgba(0, 0, 0, .5);
    background-color: rgba(28, 28, 28, 0.5) !important;
    backdrop-filter: blur(12px);
    border-radius: 3.2rem;
    margin-left: 1.6rem;
    margin-right: 1.6rem;
  }
  
  
  /* INTRO SECTION */
  .landing-section-intro {
    position: relative;
    margin-top: -27rem;
    z-index: 10;
    @media (min-width: 479px) {
      margin-top: -20rem;
    }
  }
  .landing-section-intro:after {
    background: linear-gradient(90deg, rgba(137,15,213,0) 0%, rgba(137,15,213,0.5) 25%);
    content: '';
    position: absolute;
    right: 3.2rem;
    left: -15rem;
    bottom: 0;
    backdrop-filter: blur(12px);
    border-top-right-radius: 4.8rem;
    border-top-left-radius: 2.4rem;
    border-bottom-left-radius: 2.4rem;
  }
  @media (min-width: 768px) {
    .landing-section-intro:after {
      height: 12rem;
    }
  }
  @media (max-width: 767px) {
    .landing-section-intro:after {
      height: 20rem;
    }
  }

  /* BEYOND THE TRACK */
  .landing-section-features__media {
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
  
    img {
      box-shadow: 0 0 2.4rem 0 rgba(0, 0, 0, .5);
      border-radius: 2.4rem;
    }
    
    @media (min-width: 768px) {
      margin-top: -6.4rem;
      width: 40rem;
    }
  }
  .landing-section-features {
    position: relative;
    padding-top: 5rem;
    z-index: 10;
    @media (min-width: 768px) {
      padding-top: 10rem;
    }
  }
  .landing-section-features:after {
    background: linear-gradient(to bottom, rgb(137, 15, 213, .5), #551a7a);
    content: '';
    position: absolute;
    right: 3.2rem;
    top: 0;
    bottom: 0;
    backdrop-filter: blur(12px);
    border-bottom-right-radius: 4.8rem;
    width: 120px;
  }
  .landing-section-features:before {
    background: #551a7a;
    content: '';
    height: 120px;
    position: absolute;
    right: 15.2rem;
    bottom: 0;
  }
  
  @media (max-width: 479px) {
    .landing-section-features:before {
      width: 32%;
    }
  }
  @media (min-width: 480px) {
    .landing-section-features:before {
      width: 45%;
    }
  }
  @media (min-width: 600px) {
    .landing-section-features:before {
      width: 60%;
    }
  }
  @media (min-width: 768px) {
    .landing-section-features:before {
      width: 68%;
    }
  }
  
  
  /* reusable landing section */
  .landing-section {
    position: relative;
    padding-top: 9.6rem;
    padding-bottom: 9.6rem;
    margin-top: -9.6rem;
  }
  .landing-section:after {
    background: #551a7a;
    content: '';
    position: absolute;
    left: 5.6rem;
    top: 0;
    bottom: 0;
    backdrop-filter: blur(12px);
    border-top-left-radius: 4.8rem;
    // border-bottom-left-radius: 4.8rem;
    z-index: -10;
    width: 120px;
  }
  // .landing-section--right:after {
  //   left: initial;
  //   right: 5.6rem;
  //   border-bottom-right-radius: 4.8rem;
  //   border-top-right-radius: 4.8rem;
  //   border-top-left-radius: initial;
  //   border-bottom-left-radius: initial;
  // }
  // .landing-section--right-bottom:before {
  //   background: #551a7a;
  //   content: '';
  //   height: 120px;
  //   position: absolute;
  //   right: 15.2rem;
  //   bottom: 0;
  //   width: 68%;
  // }
  // .landing-section--right-top:before {
  //   background: #551a7a;
  //   content: '';
  //   height: 120px;
  //   position: absolute;
  //   right: 15.2rem;
  //   top: 0;
  //   width: 68%;
  // }
  .landing-section__content {
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
    padding: 2.4rem 1.6rem;
    z-index: 10;

    &--reverse {
      flex-direction: column-reverse;
    }
    
    @media (min-width: 768px) {
      flex-direction: row;
      padding: 3.2rem 2.4rem;
    }

    &__media {
      @media (min-width: 768px) {
        margin-bottom: -6.4rem;
        margin-top: -6.4rem;
      }
    }
  }
  
  
  /* CONTACT SECTION */
  .landing-section-contact {
    position: relative;
  }
  .landing-section-contact__media {
    width: 20rem;
  }
  .landing-section-contact__info {
    margin-left: -9.6rem;
  }


  /* -----------------*/
  /*  ---- 2025 ----  */
  /* -----------------*/

  