.heading-1, .heading-2, .heading-3, .heading-4, .heading-5, .heading-6 {
    font-weight: 400;
    line-height: 1;
  }
  
  .heading-1 {
    font-family: "Bungee", sans-serif;
    font-size: 4.8rem;
    @media (max-width: 639px) {
      font-size: 3.2rem;
    }
    @media (max-width: 480px) {
      font-size: 2.4rem;
    }
  }
  .heading-2 {
    font-family: "Bungee", sans-serif;
    font-size: 3.2rem;
    @media (max-width: 639px) {
      font-size: 2.8rem;
    }
    @media (max-width: 480px) {
      font-size: 2.4rem;
    }
  }
  .heading-3 {
    font-family: "Bungee", sans-serif;
    font-size: 2.8rem;
    @media (max-width: 639px) {
      font-size: 2.4rem;
    }
    @media (max-width: 480px) {
      font-size: 2rem;
    }
  }
  .heading-4 {
    font-family: "Bungee", sans-serif;
    font-size: 2rem;
  }
  .heading-5 {
    font-family: "Lato", sans-serif;
    font-size: 2rem;
    letter-spacing: 1.4rem;
    text-transform: uppercase;
  }
  .heading-6 {
    font-family: "Lato", sans-serif;
    font-size: 1.6rem;
    letter-spacing: 1.2rem;
    text-transform: uppercase;
  }