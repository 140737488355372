.ar-container {
  background-color: #f0f5f5;
  text-align: center;
  position: relative;
  overflow: hidden;
  top: 65px;
  width: 100vw;
  height: 70vh;
  display: flex;
  flex-direction: column;
}

.model-viewer-wrapper {
  flex: 1;
  position: relative;
  overflow: hidden;
}
model-viewer {
  width: 100%;
  height: 100%;
}
model-viewer::before {
  font-size: calc(10vw + 10vh);
  text-align: center;
  position: absolute;
  background: linear-gradient(to bottom, rgba(0,0,0, .25) 0%, rgba(0,0,0, 0) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  top: 25%;
  left: 50%;
  line-height: 1;
  transform: translate(-50%, -50%);
  font-family: 'Bungee', sans-serif;
  white-space: nowrap; 
  overflow: hidden;
}
/* Optional: Further adjustments for very small screens */
@media (max-width: 480px) {
  model-viewer::before {
    font-size: calc(4vw + 4vh); /* Adjust font size for very small screens */
  }
}
.ar-button {
  align-items: center;
  background-color: rgba(0, 0, 0, .25);
  color: white;
  cursor: pointer;
  font-size: 14px;
  top: 0;
  padding: 8px 16px;
}
.ar-button img {
  width: 15px;
  margin-right: 8px;
}

.ar-badge {
  background-color: rgba(0, 0, 0, .25);
  position: absolute;
  right: -70px;
  top: 2rem;
  width: 250px;
  transform: rotate(45deg);
  padding: 8px;
}
.ar-badge span {
  font-size: 12px;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 0.05em;
  color: white;
}
@media (max-width: 768px) {
  .ar-badge {
    display: none;
  }
}

.ar-history {
  background-color: rgba(0, 0, 0, .25);
  backdrop-filter: blur(12px);
  position: absolute;
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%);
}

.Hotspot {
  background: rgb(255, 255, 255);
  border-radius: 32px;
  border: 0px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 2px 4px;
  box-sizing: border-box;
  cursor: pointer;
  height: 24px;
  padding: 8px;
  position: relative;
  transition: opacity 0.3s;
  width: 24px;
}
.Hotspot > * {
  opacity: 0;
  display: block;
  transform: translateY(-50%);
}

.Hotspot:not([data-visible]) {
  background: transparent;
  border: 4px solid #fff;
  box-shadow: none;
  height: 32px;
  pointer-events: none;
  width: 32px;
}
.Hotspot:not([data-visible]) > * {
  opacity: 0;
  pointer-events: none;
  transform: translateY(calc(-50% + 4px));
  transition: transform 0.3s, opacity 0.3s;
}

.Hotspot:focus {
  border: 4px solid rgb(0, 128, 200);
  height: 32px;
  outline: none;
  width: 32px;
  .HotspotAnnotation {
    opacity: 1;
  }
}

.HotspotAnnotation {
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  color: rgba(0, 0, 0, 0.8);
  display: block;
  font-family: Futura, Helvetica Neue, sans-serif;
  font-size: 18px;
  font-weight: 700;
  left: calc(100% + 1em);
  max-width: 128px;
  overflow-wrap: break-word;
  padding: 0.5em 1em;
  position: absolute;
  top: 50%;
  width: max-content;
}

.progress-bar {
  display: block;
  width: 33%;
  height: 10%;
  max-height: 2%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
  border-radius: 25px;
  box-shadow: 0px 3px 10px 3px rgba(0, 0, 0, 0.5), 0px 0px 5px 1px rgba(0, 0, 0, 0.6);
  border: 1px solid rgba(255, 255, 255, 0.9);
  background-color: rgba(0, 0, 0, 0.5);
}

.progress-bar.hide {
  visibility: hidden;
  transition: visibility 0.3s;
}

.update-bar {
  display: block;
  width: 0%;
  height: 100%;
  background-color: #dddddd;
  border-radius: 25px;
  transition: width 0.3s;
}
